
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; 

@font-face {
  font-family: "Montserrat";
  src: url("./static/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Dancing Script */
@font-face {
  font-family: "Dancing Script";
  src: url("./static/fonts/DancingScript-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

/* Satisfy */
@font-face {
  font-family: "Satisfy";
  src: url("./static/fonts/Satisfy-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gugi';
  src: url('./static//fonts//Gugi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.font-Dancing-Script {
  font-family: 'Dancing Script', cursive;
}

.font-Satisfy {
  font-family: 'Satisfy';
}

.font-Montserrat {
  font-family: 'Montserrat';
}

* {
margin: 0;
padding: 0;
}


html {
  background-color:#faf2e9;

}
body {
  font-family: "Montserrat";
  font-size: 1.5rem;
  background-color: #2a2a2a;

  display: block !important;
  max-width: 1600px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-out {
  animation: fadeInOut 6s ease-in-out infinite;
}


.transition-transform {
  transition: transform 0.7s ease-in-out;
}

.translate-x-0 {
  transform: translateX(0);
}

.-translate-x-full {
  transform: translateX(-100%);
}

/* Custom styles for underline effect */
.text-lg {
  position: relative;
  overflow: hidden;
}

.text-lg::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color: #D7B48A;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.text-lg:hover::after {
  transform: scaleX(1);
}
/* Custom styles for underline effect in footer */
.relative::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  background-color:#babfaa; /* Zmienna dla gold */
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

a:hover::after {
  transform: scaleX(1);
}

.slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.slick-list {
  overflow: hidden;
}
.slick-track {
  display: flex;
}

/* TransitionOverlay.css */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #2a2a2a;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 0.5s ease;
}

.overlay.show {
  opacity: 1;
  pointer-events: all;
}

.overlay.hide {
  opacity: 0;
  pointer-events: none;
}

.overlay-content .logo {
  width: 200px;
}


/* Zmiana koloru paska przewijania dla WebKit */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #D7B48A; /* Kolor tła paska przewijania */
}

::-webkit-scrollbar-thumb {
  background: #121210; /* Kolor suwaka */
  border-radius: 6px; /* Zaokrąglenie krawędzi suwaka */
}

::-webkit-scrollbar-thumb:hover {
  background: #2a2a2a; /* Kolor suwaka po najechaniu */
}


.transition-opacity {
  transition: opacity 1s ease;
}

.transition-transform {
  transition: transform 1s ease;
}



.focus\:border-gold:focus {
  border-color: #D7B48A; /* Złota ramka na focus */
  outline: none; /* Usuwa domyślną ramkę */
  box-shadow: 0 0 5px #D7B48A; /* Dodatkowy cień dla lepszego efektu wizualnego */
}

/* Animations for block elements */
.transition-block {
  opacity: 0; /* Hidden by default */
  transform: translateX(-50%); /* Positioned off to the left */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.transition-block.active {
  opacity: 1; /* Visible when active */
  transform: translateX(0); /* Positioned normally */
}

@media (prefers-reduced-motion: no-preference) {
  .transition-block {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
}

.krok-img {
  border-top: 10px solid white;
  border-right: 10px solid white;

}

